export default [
  // {
  //   key: 'image',
  //   initKey: 'profilePicture',
  //   label: 'field.image',
  //   type: 'async-single-image',
  //   rules: '',
  //   pw: 200,
  //   ph: 200,
  //   fullWidth: false,
  //   cols: 12,
  // },
  {
    key: 'name',
    label: 'field.name',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    rules: 'required|min:9|max:10',
    type: 'tel',
  },
  {
    key: 'gender',
    label: 'field.gender',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.male', value: 'male' },
      { text: 'field.female', value: 'female' },
    ],
  },
  {
    key: 'roles',
    label: 'field.role',
    rules: 'required',
    type: 'asyn-multi-selection',
    repository: 'role',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'note',
    label: 'field.note',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|password|min:8',
    type: 'password',
    hideInView: true,
    // description: "general.passwordDescription",
    // hideDescription: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
