<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data"></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                v-if="$can('create', resource)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const UserRepository = Repository.get("user");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        image: null,
        name: null,
        dapartmentId: null,
        phoneNumber: null,
        gender: "male",
        roles: [],
        note: null,
        username: null,
        password: null,
        isEnable: true,
      },
      loading: false,
    };
  },
  methods: {
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      UserRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
        value.rules = value.rules.replace("", "required|");
      }

      return value;
    });

    const resource = "user";
    const route = "user";
    return { fields, resource, route };
  },
};
</script>